.link-carpo {
    font-family: 'andromeda';
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    white-space: nowrap;
    color: #21e2e1;
    cursor: pointer;
}

.link-carpo::before,
.link-carpo::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.link-carpo::before {
    content: '';
}
.link-carpo:hover{
    color: rgba(255, 255, 255, 0.5);
}


.link-carpo::before {
    transform-origin: 0% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

.link-carpo:hover::before {
    transform: scale3d(1, 1, 1);
}

.link-carpo::after {
    content: '';
    top: calc(100% + 4px);
    transition: transform 0.3s;
    transform-origin: 100% 50%;
}

.link-carpo:hover::after {
    transform: scale3d(0, 1, 1);
}

.loginForm{
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    outline: none;
    margin: 0;
    padding: 0;
}
.join-content{
    min-width: 420px;
    max-width: 420px;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(20px);
    letter-spacing: initial;
    padding: 40px 40px;
    clip-path: polygon(20px 0px, 100% 0px, calc(100% - 20px) 0px, 100% 20px, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20px 100%, 0px calc(100% - 20px), 0px 20px);
}
.join-item{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 20px;
}
.join-form-label{
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.69);
    letter-spacing: 2px;
    font-family: 'contherregular';
    font-weight: 500;
}
.join-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #21e2e1;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border-left: 1px solid rgba(255, 255, 255, .001);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@keyframes grain {

    0%,
    100% {
        transform: translate(0, 0);
    }

    10% {
        transform: translate(-5%, -10%);
    }

    20% {
        transform: translate(-15%, 5%);
    }

    30% {
        transform: translate(7%, -25%);
    }

    40% {
        transform: translate(-5%, 25%);
    }

    50% {
        transform: translate(-15%, 10%);
    }

    60% {
        transform: translate(15%, 0%);
    }

    70% {
        transform: translate(0%, 15%);
    }

    80% {
        transform: translate(3%, 35%);
    }

    90% {
        transform: translate(-10%, 10%);
    }
}

.login-warp-cover {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

.login-warp-cover:before {
    animation: grain 8s steps(10) infinite;
    background-image: url(https://assets.darkstar.center/frontend/assets/img/login/noise-transparent.png);
    content: '';
    height: 300%;
    left: -50%;
    opacity: 0.85;
    position: absolute;
    top: -100%;
    width: 300%;
    z-index: 1;
}

.link-metis {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    color: rgba(255, 255, 255, .5);
}

.link-metis:hover {
    color: #ffffff;
}

.link-metis::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

.link-metis:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}

.join-item-tips {
    font-size: 12px;
    padding: 10px 0;
    color: rgba(255, 255, 255, .5);
    display: flex;
    margin-top: -20px;
    margin-bottom: 20px;
}

.outer-btn {
    position: relative;
    display: flex;
    font-family: 'andromeda';
    text-transform: uppercase;
    letter-spacing: initial;
    font-weight: 600;
    cursor: pointer;
}

.outer-btn:before,
.outer-btn:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 3px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    transition: all 0.5s;
}

.outer-btn:before {
    border-left: 1px solid rgba(255, 255, 255, .2);
    left: 0;
}

.outer-btn:after {
    border-right: 1px solid rgba(255, 255, 255, .2);
    right: 0;
}

.outer-btn-back {
    width: 100%;
    background-color: #21e2e1;
    color: #000;
    padding: 0.75rem 1.8rem;
    transition: all 0.5s;
    clip-path: polygon(10px 0px, 100% 0px, calc(100% - 10px) 0px, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
}

.outer-btn:hover .outer-btn-back {
    clip-path: polygon(12px 0px, 100% 0px, calc(100% - 12px) 0px, 100% 12px, 100% calc(100% - 12px), calc(100% - 12px) 100%, 12px 100%, 0px calc(100% - 12px), 0px 12px);
}

.outer-btn:hover:before,
.outer-btn:hover:after {
    width: 8px;
    border-top-color: #21e2e1;
    border-bottom-color: #21e2e1;
}

.outer-btn:hover:before {
    left: -5px;
}

.outer-btn:hover:after {
    right: -5px;
}

.connect-button {
    background-color: #21e2e1;
    color: #000;
    padding: 0.75rem 1.8rem;
    margin-bottom: 20px;
    letter-spacing: initial;
    border: 1px solid #21e2e1;
    min-height: 58px;
    min-width: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    transform: scale(1);
    cursor: pointer;
    clip-path: polygon(20px 0px, 100% 0px, calc(100% - 20px) 0px, 100% 20px, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20px 100%, 0px calc(100% - 20px), 0px 20px);
}

.connect-button:hover {
    clip-path: polygon(10px 0px, 100% 0px, calc(100% - 10px) 0px, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
}

.connect-button.disabled {
    filter: grayscale(100%);
}